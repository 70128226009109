














































import { Component, Vue } from 'vue-property-decorator'
import RightInformation from '@/components/RightInformation.vue'
import { getfunctionPrice } from '@/api/product'
@Component({
  components: { RightInformation }
})
export default class Price extends Vue {
  /** 轮播图*/
  functionalDetailsImgs: string[] = []

  /** 页面详情*/
  banner: string[] = []
  functionalTable = []
  created() {
    getfunctionPrice({ id: this.$route.query.key }).then((res) => {
      this.functionalDetailsImgs = res.data.functionalDetailsImgs.split(',')
      this.banner = res.data.banner.split(',')
      res.data.packageDetailsDtoList.forEach((item: any) => {
        item.packageFunctionPoints = item.packageFunctionPoint.split(',')
        item.openMode = item.openMode + ''
      })
      this.functionalTable = res.data.packageDetailsDtoList
    })
  }
  open(openMode: string, buttonLink: string) {
    if (openMode == '0') {
      window.location.href = `${buttonLink}`
    }
    if (openMode == '1') {
      window.open(buttonLink, '_blank')
    }
  }
}
